import {useCallback} from 'react';
import {ShelfType, ConditionalValues} from 'Preview3D/types';
import {TemplateVariable} from 'components/customer/Preview3D/usePreview3DData';
import useMaterialTexture from 'components/customer/Preview3D/lib/useMaterialTexture';
import {
    getShelvesFormValues,
    generateHorizontalShelves as getHorizontalShelves,
    generateComplexHorizontalShelves as getComplexHorizontalShelves,
    generateComplexVerticalShelves as getComplexVerticalShelves,
    generateUpperAndLowerShelves as getUpperAndLowerShelves,
    generateUpperAndLowerLeftRightShelves as getUpperAndLowerLeftRightShelves,
    generateKickerLadderFrames as getKickerLadderFrames,
    generateBulkheadLadderFrames as getBulkheadLadderFrames,
} from 'components/customer/Preview3DCommon/values/shelves';
import {use3DContext} from 'components/customer/Preview3D/components/Preview3DComponent';

const useShelvesDynamicValues = (shelfType: ShelfType) => {
    const {values, productDataStore} = use3DContext();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const variables: TemplateVariable = JSON.parse(
        productDataStore.current?.template_3d[0].attributes?.variables
    );
    const {carcaseThickness, exteriorThickness} = useMaterialTexture();
    const valuesWithThickness = {
        ...values,
        cabinet_carc_thickness: carcaseThickness,
        cabinet_ext_thickness: exteriorThickness,
    };
    const formValues = getShelvesFormValues(values, shelfType);

    const generateHorizontalShelves = useCallback(() => {
        return getHorizontalShelves(valuesWithThickness, formValues, variables);
    }, [formValues]);

    const generateComplexHorizontalShelves = useCallback(() => {
        return getComplexHorizontalShelves(valuesWithThickness, formValues);
    }, [formValues]);

    const generateComplexVerticalShelves = useCallback(() => {
        return getComplexVerticalShelves(valuesWithThickness, formValues);
    }, [formValues]);

    const generateUpperAndLowerShelves = useCallback(() => {
        return getUpperAndLowerShelves(valuesWithThickness, formValues);
    }, [formValues]);

    const generateUpperAndLowerLeftRightShelves = useCallback(() => {
        return getUpperAndLowerLeftRightShelves(
            valuesWithThickness,
            formValues,
            variables
        );
    }, [formValues]);

    const generateKickerLadderFrames = useCallback(() => {
        return getKickerLadderFrames(valuesWithThickness, formValues);
    }, [formValues]);

    const generateBulkheadLadderFrames = useCallback(() => {
        return getBulkheadLadderFrames(valuesWithThickness, formValues);
    }, [formValues]);

    const shelfValues: ConditionalValues[] = [
        {
            key: 'simpleShelves',
            condition: typeof values.shelves !== 'undefined',
            callback: generateHorizontalShelves,
        },
        {
            key: 'complexHorizontalShelves',
            condition: Boolean(formValues?.shelvesHorizontalCount),
            callback: generateComplexHorizontalShelves,
        },
        {
            key: 'complexVerticalShelves',
            condition: Boolean(formValues?.shelvesVerticalCount),
            callback: generateComplexVerticalShelves,
        },
        {
            key: 'pantryShelves',
            condition:
                Boolean(values?.upper_shelves) &&
                variables?.shelfType === 'PANTRY',
            callback: generateUpperAndLowerShelves,
        },
        {
            key: 'broomShelves',
            condition:
                Boolean(values?.upper_shelves) && Boolean(values?.shelves),
            callback: generateUpperAndLowerLeftRightShelves,
        },
        {
            key: 'kicker',
            condition: Boolean(formValues?.ladderFrames),
            callback: generateKickerLadderFrames,
        },
        {
            key: 'bulkhead',
            condition: Boolean(formValues?.ladderFrames),
            callback: generateBulkheadLadderFrames,
        },
    ];

    return {
        shelfValues,
    };
};

export default useShelvesDynamicValues;
